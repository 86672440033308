import React from 'react'
import styles from './Taboo.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Taboo/img_1.svg'
import image_2 from '../../image/Taboo/img_2.svg'
import image_3 from '../../image/Taboo/img_3.svg'
import image_4 from '../../image/Taboo/img_4.svg'
import image_5 from '../../image/Taboo/img_5.svg'
import image_6 from '../../image/Taboo/img_6.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Taboo = () => {

    return (
        <motion.section 
        className={styles.main} 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.2, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>ЧТО НЕЛЬЗЯ ХРАНИТЬ<span> на&#160;складе</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <p className={styles.item_text}>Легковоспламеняющиеся материалы и жидкости</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <p className={styles.item_text}>Токсичные, радиоактивные вещества и предметы</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <p className={styles.item_text}>Оружие и любые взрывоопасные вещества</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='телефон'/>
                            </div>
                            <p className={styles.item_text}>Скоропортящиеся продукты питания</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='телефон'/>
                            </div>
                            <p className={styles.item_text}>Лекарственные препараты и формы</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_6} alt='телефон'/>
                            </div>
                            <p className={styles.item_text}>Материалы источающие дым и запах</p>
                        </li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}