import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import {Advantages} from '../../components/Advantages/Advantages'
import {Callback} from '../../components/Callback/Callback'
import { Scheme } from '../../components/Scheme/Scheme'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import {Questions} from "../../components/Questions/Questions"
import {Clientele} from '../../components/Clientele/Clientele'
import { Lockers } from '../../components/Lockers/Lockers'
import { Taboo } from '../../components/Taboo/Taboo'
import { Prices } from '../../components/Prices/Prices'

export const Main = ({isOpen, popupOpen, isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen ={isOpen}
            />
            <SliderAdvantages/>
            <AboutUs
            />
            <Prices/>
            <Advantages/>
            <Taboo/>
            <Clientele/>
            <Scheme
                isOpen ={isOpen}
            />
            <Lockers/>
            <Questions/>
            <Callback
                popupOpen={popupOpen}
            />
        </section>
    )
}