import React, {useEffect} from 'react'
import styles from './ModalTime.module.scss'
import { ModalAction } from '../ModalAction/ModalAction'

const ModalTime = ({onClose}) => {

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return (
        <ModalAction onClick={onClose}>
        <div className={styles.modal} onClick={evt => evt.stopPropagation()}>
            <button className={styles.close_icon} data-test="close-button" type="button" aria-label="закрыть" onClick={onClose}></button>
            <h2 className={styles.title}><span>акция действует</span> до 31 марта!</h2>
            <p className={styles.text}>количество боксов учавствующих в акции ограничено</p>
            <ul className={styles.list}>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>2 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>3 000 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>2 900 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>3.5 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>5 250 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>4 150 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>4 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>6 000 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>5 900 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>4.5 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>6 750 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>6 650 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>5 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>7 500 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>7 400 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>5.5 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>8 250 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>8 150 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>6 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>9 000 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>8 900 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>6.5 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>9 750 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>9 650 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>7 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>10 500 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>10 400 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>7.5 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>11 250 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>11 150 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_red}>8 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle_red}>9 600 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle_red}>9 500 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle_red}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>8.5 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>12 750 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>12 650 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>9 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>13 250 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>13 150 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>9.5 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>13 750 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>13 650 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
                <li className={styles.item}>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>Размер</p>
                        <p className={styles.subtitle_green}>10 м² </p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>1 мес.</p>
                        <p className={styles.subtitle}>14 250 ₽</p>
                    </div>
                    <div className={styles.item_box}>
                        <p className={styles.subtitle_black}>3 мес.</p>
                        <p className={styles.subtitle}>14 150 ₽</p>
                    </div>
                    <div className={styles.item_box_free}>
                        <p className={styles.subtitle_black}>Кол-во</p>
                        <p className={styles.subtitle}>1</p>
                    </div>
                    <div className={styles.item_box_button}>
                        <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://wa.me/+79163244554" aria-label="связаться вацап">забронировать</a>
                    </div>
                </li>
            </ul>
        </div>
        </ModalAction>
    );
};

export default ModalTime