import React from 'react'
import styles from './Prices.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Prices = () => {

    return (
        <motion.section 
        className={styles.main}
        id='Services'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>цена на <span>аренду ЛОКЕРА</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
            <li className={styles.item}>
                <div className={styles.item_box}>
                    <p className={styles.subtitle_black}>Размер</p>
                    <p className={styles.subtitle_green}>1 м³</p>
                </div>
                <div className={styles.item_box}>
                    <p className={styles.subtitle_black}>1 мес.</p>
                    <p className={styles.subtitle}>1 790 ₽</p>
                </div>
                <div className={styles.item_box}>
                    <p className={styles.subtitle_black}>3 мес.</p>
                    <p className={styles.subtitle}>1 690 ₽</p>
                </div>
                <div className={styles.item_box}>
                    <p className={styles.subtitle_black}>6 мес.</p>
                    <p className={styles.subtitle}>1 590 ₽</p>
                </div>
                <div className={styles.item_box_button}>
                    <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                </div> 
            </li>
        </motion.ul>   
        <motion.h2 className={styles.title} variants={titleAnimation}>цены на <span>аренду боксов</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>2 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>3 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>3 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>3 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>3.5 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>6 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>6 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>6 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>4 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>7 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>7 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>7 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>4.5 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>8 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>8 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>8 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>5 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>9 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>9 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>9 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>5.5 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>10 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>10 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>10 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>6 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>11 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>11 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>11 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>6.5 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>12 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>12 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>12 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>7 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>13 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>13 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>13 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>7.5 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>14 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>14 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>14 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>8 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>15 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>15 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>15 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>8.5 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>16 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>16 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>16 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li> 
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>9 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>17 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>17 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>17 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li> 
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>9.5 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>18 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>18 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>18 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>10 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>19 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>19 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>19 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>13 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>25 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>25 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>25 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>Размер</p>
                            <p className={styles.subtitle_green}>15 м²	</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>1 мес.</p>
                            <p className={styles.subtitle}>29 900 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>3 мес.</p>
                            <p className={styles.subtitle}>29 800 ₽</p>
                        </div>
                        <div className={styles.item_box}>
                            <p className={styles.subtitle_black}>6 мес.</p>
                            <p className={styles.subtitle}>29 700 ₽</p>
                        </div>
                        <div className={styles.item_box_button}>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>забронировать</a>
                        </div> 
                    </li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}