import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/InitialPage/camera.svg'
import icon_2 from '../../image/InitialPage/time.svg'
import icon_3 from '../../image/InitialPage/balon.svg'
import icon_4 from '../../image/InitialPage/thermometer.svg'

const titleAnimation = {
    hidden: {
        x: -800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: 800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}


export const InitialPage = () => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                <div className={styles.item}>
                    <div className={styles.overlay}>
                        <div className={styles.container}>
                            <motion.h1 className={styles.title} variants={titleAnimation}>АРЕНДА СКЛАДОВ ДЛЯ ХРАНЕНИЯ ВЕЩЕЙ</motion.h1>
                            <motion.div className={styles.box} variants={subtitleAnimation}>
                                <div className={styles.box_item}>
                                    <img className={styles.image} src={icon_1} alt='иконка'/>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Ведется видеонаблюдение</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <img className={styles.image} src={icon_2} alt='иконка'/>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Круглосуточный доступ</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <img className={styles.image} src={icon_3} alt='иконка'/>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Пожарная сигнализация</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <img className={styles.image} src={icon_4} alt='иконка'/>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Оптимальная температура</p>
                                    </div>
                                </div>
                            </motion.div>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>арендовать бокс</a>
                        </div>
                    </div>
                </div>  
        </motion.section>
    )
}