import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main}
            id='AboutUs' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                    <motion.div className={styles.box_img} variants={imageAnimation}>
                        
                    </motion.div>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Аренда склада  <span>для&#160;хранения</span></h2>
                        <p className={styles.text}>&#8195;Аренда склада для хранения личных вещей является популярной услугой для многих городов. Это прекрасный способ освободить место на время ремонта, или сохранить место на время переезда. Также эта услуга необходима, когда не хватает места для хранения спортивных товаров в не сезон.</p>
                        <p className={styles.text}>Необходимость в свободном пространстве есть у многих людей, а также желании избавиться от ненужных или мешающих предметов. Для этого можно арендовать помещение для хранения мебели, бытовой техники, спортивных товаров и т.д.</p>
                        <p className={styles.text}>Аренду индивидуального склада для хранения имущества на любой срок, в Москве предлагает компания ЭЛАРА.</p>
                        <p className={styles.text}>Склад индивидуального хранения - это возможность арендовать необходимую площадь для безопасного хранения любых грузов на нужный период времени.</p>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>арендовать бокс</a>
                    </motion.div>
            </div>
        </motion.section>
    )
}