import React, {useCallback, useState, useEffect} from 'react'
import app from './App.module.scss'
import {Main} from '../../pages/Main/Main'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'
import { UpButton } from '../UpButton/UpButton'
import { MenuMobile } from '../MenuMobile/MenuMobile'
import { ModalForm } from '../ModalForm/ModalForm'
import { ButtonCommunication } from '../ButtonCommunication/ButtonCommunication'
import { Modal } from '../Modal/Modal'
import  ModalTime  from '../ModalTime/ModalTime'

function App() {

  useEffect(() => {

    setTimeout(function() {
      setIsModalTime(true);
    }, 10000);
  }, []);

  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isModalTime, setIsModalTime] = useState(false)

  const vh = window.innerHeight / 100;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  const handleModalTimeClose = useCallback(() => {
    setIsModalTime(false)
  }, []);


  return (
    <div className={app.page}>
      <Header
        isOpen={handleOpenMenuMobile}
      />
      <Main
        isOpen={handleFormOpen}
        popupOpen={handleOpen}
      />
      <Footer/>
      <UpButton/>
      <ButtonCommunication
        isOpen={handleFormOpen}
      />
      {isMenuMobile && <MenuMobile
        onClose={handleCloseMenuMobile}
      />}
      {isPopupFormOpen && 
      <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isModalTime && <ModalTime
        onClose={handleModalTimeClose}
      ></ModalTime>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
    </div>
  );
}

export default App;
