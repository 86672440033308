import React from 'react'
import { motion } from "framer-motion"
import styles from './Lockers.module.scss'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const Lockers = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                    <motion.div className={styles.box_img} variants={imageAnimation}>
                        
                    </motion.div>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>ЛОКЕРЫ ДЛЯ  <span>ХРАНЕНИЯ&#160;ВЕЩЕЙ</span></h2>
                        <p className={styles.text}>&#8195;Для вашего удобства центр хранения компании ЭЛАРА теперь оснащен локерами для хранения вещей!</p>
                        <p className={styles.text}>Локер (англ. locker) — это небольшой бокс, место для хранения, предназначенное для хранения вещей, документов, инструментов и других не крупно габаритных предметов.</p>
                        <p className={styles.text}>Размер локера по высоте, глубине и ширине 1 метр, а объёмом 1 кубический метр. В таком локере очень удобно хранить колеса вашего автомобиля.</p>
                        <p className={styles.text}>Стоимость хранения в локере ниже стоимости аренды минимального «стандартного» бокса.</p>
                        <p className={styles.text}>Может показаться что это локер это маленький формат бокса, но на самом деле в него может поместиться достаточно много сезонных и бытовых вещей. Подходит для хранения бытовой техники, инструментов, книг и прочего, что по габаритам в длину, высоту и ширину не превышает 1-го метра.</p>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>арендовать бокс</a>
                    </motion.div>
            </div>
        </motion.section>
    )
}