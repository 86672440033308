import React from 'react'
import { motion } from "framer-motion"
import styles from './Clientele.module.scss'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const Clientele = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                    <motion.div className={styles.box_img} variants={imageAnimation}>
                        
                    </motion.div>
               
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>КОМУ ПОДХОДИТ ХРАНЕНИЕ ВЕЩЕЙ <span>НА&#160;СКЛАДЕ</span></h2>
                        <p className={styles.text}>&#8195;Преимущества наших помещений заключаются в полном соответствии всем существующим требованиям безопасности. У нас вы можете выбрать и забронировать нужный размер мини-склада онлайн (от 1 м²)</p>
                        <p className={styles.text}>Хранение вещей на наших складах подойдет:</p>
                        <p className={styles.item_text}>Предпринимателям для хранения архивов, незадействованной техники, офисной мебели, товаров, большие контейнеры, оборудования и других предметов;</p>
                        <p className={styles.item_text}>Спортсменам под спортинвентарь, велосипеды, технику, спортивные снаряды и многое другое;</p>
                        <p className={styles.item_text}>Интернет-магазинам для распределения своих товаров;</p>
                        <p className={styles.item_text}>Владельцам автомобилей для сезонного хранения колес, асексуаров и инструментов;</p>
                        <p className={styles.item_text}>Людям осуществляющим переезд или ремонт. Складской бокс в таком случае выступает в роли передержки разных личных вещей, бытовой техники и мебели;</p>
                        <p className={styles.item_text}>Семьям с детьми для расположения детских вещей, самокатов, колясок и т.д.;</p>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79163244554' aria-label='связаться вацап'>арендовать бокс</a>
                    </motion.div>
            </div>
        </motion.section>
    )
}